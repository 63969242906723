@font-face {
  font-family: 'yyx';
  src: url('./assets/Fonts/regular.eot');
  src: url('./assets/Fonts/regular.woff') format('woff'), url('./assets/Fonts/regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'yyx';
  src: url('./assets/Fonts/semibold.eot');
  src: url('./assets/Fonts/semibold.woff') format('woff'), url('./assets/Fonts/semibold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    @apply bg-white;
}

input,
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="tel"],
[type="time"],
[type="week"],
select,
textarea {
    user-select: auto;
}
